.ant-col-12.auth-form-container {
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  max-width: 480px;
}

.ant-form-item.form-title {
  margin-bottom: 10px;
}

.ant-form-item.form-title .ant-form-item-label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  text-align: center;
}

.ant-form-item.form-left-title .ant-form-item-label {
  text-align: left !important;
}

.ant-form .ant-row.form-left-title .ant-form-item-label>label {
  font-size: 18px !important;
  line-height: 22px !important;
}

.form-title .ant-form-item-control {
  display: none;
}

.ant-form-item.form-sub-title {
  padding: 0 55px;
  margin-bottom: 10px;
}

.ant-form-item.form-sub-title .ant-form-item-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #262626;
}

.form-sub-title .ant-form-item-control {
  display: none;
}

.ant-form-item.form-desc {
  padding: 0 20px;
  margin-bottom: 15px;
  text-align: center;
}

.ant-form-item.form-desc .ant-form-item-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #262626;
}

.ant-form-item.form_image {
  text-align: center;
}

.ant-form-item.form-footer-desc {
  text-align: center;
  padding: 0 10px;
}

.ant-form-item.form-main-label .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item.form-main-label {
  margin-bottom: 0;
}

.ant-form-item.form-sub-label .ant-form-item-label>label {
  color: #585858;
  font-size: 12px;
}

.ant-form-item-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #262626;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.ant-btn-primary.form-button {
  width: 100%;
  margin-bottom: 24px;
  background: #050593;
  border-color: #050593;
  border-radius: 5px;
}

.ant-btn-primary.form-button:hover, .ant-btn-primary.form-button:focus, .ant-btn-primary.form-button:active {
  background: #050593;
  border-color: #050593;
}

.ant-form-item-required {
  display: flex;
  align-items: center;
  color: #262626;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 2px;
}

.ant-form-item.form-footer {
  text-align: center;
  margin-bottom: 0;
}

.form-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #050593;
}

.form-text:hover {
  color: #050593;
}

.ant-alert.form-error {
  margin-bottom: 15px;
}

.ant-form-item.form-error-desc {
  margin-bottom: 0;
}

.ant-alert-message, .ant-alert-description {
  font-style: normal;
  font-weight: 500;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 4px;
  flex: none;
}

/* .ant-alert-message {
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  order: 0;
} */

.ant-alert-description {
  font-size: 14px;
  line-height: 22px;
  /* color: #050593; */
  order: 1;
}

.custom-modal.ant-modal .ant-modal-content .ant-modal-body>div {
  border: 1px solid #C4C4C4;
  padding: 10px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.error-alert {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.error-alert li {
  font-size: 12px;
  color: #868686
}