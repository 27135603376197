@import "~antd/dist/antd.css";
@import "../../commons/styles/variables.scss";

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: local("Lato"),
    url("../../assets/font/Lato/Lato-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Lato", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

.ant-layout-header {
  padding: 0;
  text-align: center;
  margin: 55px 0;
  background: transparent;
}

.ant-layout-content {
  position: relative;
  padding: 20px;
  z-index: 2;
}

.ant-layout-footer {
  padding: 0;
  margin-top: -60px;
  
  .ant-image {
    width: 100%;
  }
}

.ant-menu-item-selected {
  background-color: lighten($color-primary, 65%) !important;
}