// Colours
$color-white: #ffffff;
$color-dark: #262626;
$color-primary: #050593;
// $color-secondary: #003172;
$color-grey-overlay: rgba(0, 0, 0, 0.15);
$color-border-light-grey: #d9d9d9;
$color-border-dark-grey: #F0F3F7;

$color-form-grey: #f9f9f9;

//
// Spacing Units - used for paddings & margins
//

@function unit($scale, $base: 4px) {
  @return $scale * $base;
}

// 2px
$size-xxs: unit(0.5);

// 4px
$size-base: unit(1);

// 8px
$size-xs: unit(2);

// 12px
$size-s: unit(3);

// 16px
$size-m: unit(4);

// 32px
$size-l: unit(8);
