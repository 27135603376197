@import "../../commons/styles/variables.scss";

.ant-layout-header {
  padding: 0;
}
.auth-container.ant-layout {
  .ant-layout-content {
    padding: 0;
    margin-top: -20px;
    margin-bottom: 174px;
  }

  .ant-layout-footer {
    position: fixed;
    bottom: 0;
    margin-top: 0;
  }
}

.menu-flags {
  line-height: 13px;
  margin-bottom: -6px;

  &-button {
    padding: 6px 5px 4px 0 !important;
    border-radius: 3px !important;
    max-height: 32px;

    > span:first-child {
      width: 120px;
    }
  }
}

.show-prefix .ant-input-prefix {
  background: $color-form-grey;
  font-size: 11px;
  padding: 0 6px;
  font-weight: 600;
}
